<script setup lang="ts">
interface Blob {
  color: string;
  position: string;
  size?: string;
}

interface Props {
  blobs?: Blob[];
  quality?: 'low' | 'medium' | 'high';
  enableDynamicBlur?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  blobs: () => [
    { color: 'bg-blue-600/10', position: 'right-[5%] top-[5%]' },
    { color: 'bg-teal-600/10', position: 'left-[5%] top-[15%]' },
    { color: 'bg-purple-500/10', position: '-bottom-[10%] right-[5%]' },
    { color: 'bg-amber-700/10', position: '-top-[10%] left-[15%]' },
  ],
  quality: 'medium',
  enableDynamicBlur: false,
});

// Performance settings based on quality
const blurSettings = computed(
  () =>
    ({
      low: { blob: '60px', backdrop: '30px' },
      medium: { blob: '80px', backdrop: '40px' },
      high: { blob: '125px', backdrop: '80px' },
    })[props.quality]
);

// Dynamic blur for scroll performance
const scrollBlurModifier = ref(1);
let scrollTimeout: NodeJS.Timeout;

if (props.enableDynamicBlur) {
  onMounted(() => {
    if (window.innerWidth < 768) {
      visibleBlobs.value = props.blobs.slice(0, 2);
    }

    window.addEventListener('resize', () => {
      visibleBlobs.value =
        window.innerWidth < 768 ? props.blobs.slice(0, 2) : props.blobs;
    });
    window.addEventListener('scroll', handleScroll, { passive: true });
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
}

function handleScroll() {
  scrollBlurModifier.value = 0.5;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    scrollBlurModifier.value = 1;
  }, 150);
}

// Optimize rendering with computed styles
const styles = computed(() => ({
  '--blob-blur': `${parseInt(blurSettings.value.blob) * scrollBlurModifier.value}px`,
  '--backdrop-blur': `${parseInt(blurSettings.value.backdrop) * scrollBlurModifier.value}px`,
  transform: 'translateZ(0)',
  willChange: 'transform',
  contain: 'paint layout',
  backfaceVisibility: 'hidden',
  perspective: '1000',
}));

// Reduce blobs on mobile
// const visibleBlobs = computed(() =>
//   process.client && window.innerWidth < 768
//     ? props.blobs.slice(0, 2)
//     : props.blobs
// );

const visibleBlobs = ref(props.blobs);
</script>

<template>
  <div
    class="pointer-events-none fixed inset-0 -z-10 overflow-hidden"
    :style="styles">
    <div class="relative h-full w-full transform-gpu">
      <div
        v-for="(blob, index) in visibleBlobs"
        :key="`blob-${index}`"
        :class="[
          'absolute transform-gpu rounded-full',
          blob.size || 'h-[50vh] w-[80vw] sm:w-[60vw] md:w-[45vw] lg:w-[30vw]',
          blob.color,
          blob.position,
        ]"
        aria-hidden="true" />
    </div>
  </div>
</template>

<style scoped>
.transform-gpu {
  transform: translate3d(0, 0, 0);
}

div[aria-hidden='true'] {
  backdrop-filter: blur(var(--backdrop-blur));
  -webkit-backdrop-filter: blur(var(--backdrop-blur));
  filter: blur(var(--blob-blur));
  opacity: 0.9;
  transition: filter 0.2s ease-out;
  contain: paint layout;
}

@media (prefers-reduced-motion: reduce) {
  div[aria-hidden='true'] {
    transition: none !important;
  }
}
</style>
