<template>
  <div>
    <SolaraBackgroundGlassmorphism quality="low" :enable-dynamic-blur="true" />
    <main
      class="grid h-screen w-screen grow place-items-center overflow-hidden md:grid-cols-3">
      <div
        class="flex w-full flex-1 flex-col justify-center px-10 py-12 md:col-span-2">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
          <SolaraLogo class="mx-auto block" />
          <h2
            v-if="$slots.title"
            class="mt-5 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
            <slot name="title" />
          </h2>
          <p
            v-if="$slots.description"
            class="mt-5 text-center text-gray-500 sm:text-sm sm:leading-6">
            <slot name="description" />
          </p>
        </div>

        <!-- Form Content -->
        <div class="mx-auto mt-10 w-full max-w-sm">
          <slot />
        </div>
      </div>

      <!-- eslint-disable-next-line vue/html-self-closing -->
      <img
        class="h-full w-full rounded-t-3xl object-cover md:-order-1 md:rounded-r-3xl md:rounded-tl-none"
        src="/assets/images/log-in-leaves.jpeg"
        alt="solara-login-leaves" />
    </main>
  </div>
</template>

<style scoped>
/* Base styles */
:root {
  --blur-strength: 20px;
}

/* Glassmorphic backdrop blur */
.backdrop-blur-md {
  backdrop-filter: blur(var(--blur-strength));
}

/* iOS-specific backdrop blur optimization */
@supports (-webkit-backdrop-filter: none) {
  .backdrop-blur-md {
    -webkit-backdrop-filter: blur(var(--blur-strength));
  }
}

/* Fix for mobile Safari viewport height */
@supports (-webkit-touch-callout: none) {
  .min-h-full {
    min-height: -webkit-fill-available;
  }
}

/* Optimize performance on mobile */
@media (max-width: 768px) {
  :root {
    --blur-strength: 16px;
  }

  /* Blurred blobs adjusted for mobile */
  .backdrop-blur-md {
    backdrop-filter: blur(var(--blur-strength));
    -webkit-backdrop-filter: blur(var(--blur-strength));
  }
}
</style>
